import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContentContainer from "../components/ContentContainer";

const SignupSuccess = () => (
	<Layout>
		<SEO title="Anmeldung erfolgreich"/>
		<ContentContainer half className="text-center">
			<h1 className="mb-3 font-weight-bold">Anmeldung erfolgreich</h1>
			<div className="color-subtext mw-50 m-auto">
				<p className="mb-3">Du hast gerade eine Bestätigungsmail bekommen. Bitte folge den Anweisungen in der Mail um deinen Account zu aktivieren.</p>
				<p>Nach der Bestätigung kannst du dich anmelden!</p>
			</div>
		</ContentContainer>
	</Layout>
);

export default SignupSuccess;
